﻿interface Array<T> {
  firstOrDefault(predicate?: (item: T) => boolean): T;
  first(predicate?: (item: T) => boolean): T;
  last(): T;
  indexOfByPredicate(predicate?: (item: T) => boolean): number;
  any(predicate?: (item: T) => boolean): boolean;
  remove(item: T): void;
  removeWithPredicate(predicate: (item: T) => boolean): void;
  single(predicate: (item: T) => boolean): T;
  insertAt(index: number, item: T): void;
  orderBy(getOrderPropFunc: (item: T) => number): T[];
  count(predicate: (item: T) => boolean): number;
  contains(item: T): boolean;
}

// if no preficate supplied, returns the first element
// returns the first element that matches the predicate.
// if no match occured, returns null
Array.prototype.firstOrDefault = function (predicate) {
  if (!predicate) {
    return this[0];
  }

  return this.filter(predicate)[0];
};

// returns the first element if any
// if there is no first element throws exception
Array.prototype.first = function (predicate) {
  let result = this.firstOrDefault(predicate);
  if (!result) {
    throw new Error("Array.first returned no matching elements!");
  }

  return result;
};

// returns the last element of the array
Array.prototype.last = function () {
  return this[this.length - 1];
};

// returns the first index of the item in the sequence that matches the supplied predicate.
// If no match occured. returns -1
Array.prototype.indexOfByPredicate = function (predicate) {
  let index = -1;

  for (let i = 0; i < this.length; i++) {
    if (predicate(this[i])) {
      index = i;
      break;
    }
  }

  return index;
};

// if no argument supplyed returns tru or false based on wether the array is empty or not
// if an object argument is supplied returns true if the array contains the object (by reference)
// if a predicate is supplied returns true if it matches any of the elements
Array.prototype.any = function (argument) {
  let result;

  // ARG: -O-
  if (!argument) {
    result = this.length > 0;
  } else {
    // ARG: OBJECT
    if (typeof argument === "object") {
      result = this.any(function (item) {
        return item === argument;
      });
    }

    // ARG: FUNCTION (PREDICATE)
    else {
      result = this.filter(argument).length > 0;
    }
  }

  return result;
};

// removes the supplied object (by reference) from the array
Array.prototype.remove = function (objectToRemove) {
  this.removeWithPredicate(function (arrayObject) {
    return objectToRemove === arrayObject;
  });
};

// removes the element the predicate matches with
Array.prototype.removeWithPredicate = function (removePredicate) {
  for (let index = this.length - 1; index >= 0; index--) {
    if (removePredicate(this[index])) {
      this.splice(index, 1);
    }
  }
};

// returns the matching object, or throws exception if there is not a single matching object in the array
Array.prototype.single = function (arg) {
  const errorText =
    "Single function found more or less than one matching items!";

  // argument is an object or a predicate
  if (arg) {
    const anyResult = this.any(arg);
    if (!anyResult) {
      throw errorText;
    }
  }

  // no argument
  else {
    if (this.length !== 1) {
      throw errorText;
    }
  }

  return this.firstOrDefault(arg);
};

// inserts the specieifed item to an array, at the specified index
Array.prototype.insertAt = function (index, item) {
  this.splice(index, 0, item);
};

// sorts the array with the specified order function
Array.prototype.orderBy = function (getOrderPropFunc) {
  const sourceArray = this;
  let resultArray: any[] = [];

  sourceArray.forEach((sourceItem) => {
    let sourceItemValue = getOrderPropFunc(sourceItem);

    let foundIndex = -1;
    for (let i = 0; i < resultArray.length; i++) {
      let resultItem = resultArray[i];
      let resultItemValue = getOrderPropFunc(resultItem);

      if (sourceItemValue <= resultItemValue) {
        foundIndex = i;
        break;
      }
    }

    if (foundIndex === -1) {
      resultArray.push(sourceItem);
    } else {
      resultArray.insertAt(foundIndex, sourceItem);
    }
  });

  return resultArray;
};

Array.prototype.count = function (predicate) {
  if (!predicate) {
    return 0;
  }

  return this.filter(predicate).length;
};

Array.prototype.contains = function (item) {
  return this.any((e) => e === item);
};
